/* eslint-disable camelcase */
import React from 'react'
import { css } from '@emotion/core'
import {
  colors,
  spacer,
  breakpoints,
  misc,
} from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import { get } from 'lodash-es'
import ChangeEmailAndNameForm from './ChangeEmailAndNameForm'
import ChangePasswordForm from './ChangePasswordForm'
import AddPaymentMethod from './AddPaymentMethod'
import NumberInCircle from '#components/NumberInCircle/NumberInCircle'
import { useUserContext } from '#context/UserContext'

const styles = {
  container: css`
    background-color: ${colors.white};
    box-shadow: ${misc.boxShadow.default};
    border-radius: ${misc.borderRadius.md};
    ${breakpoints({
      padding: [
        `20px`,
        `20px`,
        `50px 40px`,
        `70px 60px`,
        `70px 60px`,
      ],
    })}

    .row {
      margin-bottom: 50px;
      :last-child {
        margin-bottom: 0;
      }
    }
  `,
  numberInCircle: css`
    ${spacer.mrR10};
    ${spacer.mrB20};
  `,
}

function MyAccountForm() {
  const { user } = useUserContext()
  const { first_name, last_name, email } = get(user, 'user', {})
  return (
    <>
      <div className='container-md'>
        <div css={styles.container}>
          <div className='row'>
            <div className='col-xl-3 col-lg-4 d-flex align-items-start'>
              <NumberInCircle
                number='1'
                css={styles.numberInCircle}
              />
              <Text weight='bold' size='large' color={colors.grey}>
                Personal details
              </Text>
            </div>
            <div className='offset-xl-2 col-xl-7 col-lg-8'>
              <ChangeEmailAndNameForm
                first_name={first_name}
                last_name={last_name}
                email={email}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-lg-4 d-flex align-items-start'>
              <NumberInCircle
                number='2'
                css={styles.numberInCircle}
              />
              <Text weight='bold' size='large' color={colors.grey}>
                Update password
              </Text>
            </div>
            <div className='offset-xl-2 col-xl-7 col-lg-8'>
              <ChangePasswordForm />
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-lg-4 d-flex align-items-start'>
              <NumberInCircle
                number='3'
                css={styles.numberInCircle}
              />
              <Text weight='bold' size='large' color={colors.grey}>
                Payment details
              </Text>
            </div>
            <div className='offset-xl-2 col-xl-7 col-lg-8'>
              <AddPaymentMethod />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

MyAccountForm.propTypes = {}

export default MyAccountForm
