import React, { useState, useEffect } from 'react'
import { object, string, ref } from 'yup'
// import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import Button from '@dqp/common/components/Button/Button'
import PasswordField from '@dqp/common/components/PasswordField/PasswordField'
import {
  ErrorText,
  SuccessText,
} from '@dqp/common/components/Text/Text'
import { get } from 'lodash-es'
import parseValidationErrors from '#utils/parseValidationErrors'
import { feedbackDismissDuration } from '#globals/constants'
import Stack from '#components/Stack/Stack'
import { useUpdateUser } from '#api/updateUser'
import getErrorMessage from '#utils/getErrorMessage'

const schema = object().shape({
  current_password: string().required(
    'Current Password is required.',
  ),
  password: string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .required('Please enter new Password.'),
  password_confirmation: string()
    .required('Please confirm Password.')
    .oneOf([ref('password'), null], "Passwords don't match"),
})
const ChangePasswordForm = () => {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { updateUser } = useUpdateUser()
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    isValid,
    dirty,
    handleBlur,
    isSubmitting,
    touched,
    resetForm,
  } = useFormik({
    validateOnBlur: true,
    validationSchema: schema,
    initialValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    onSubmit: async (formData, actions) => {
      try {
        const { data } = await updateUser({ variables: formData })
        const { id } = get(data, 'updateUser', {})
        if (id) {
          setSuccessMessage('Password updated')
          resetForm()
        } else {
          setErrorMessage('There was an Error')
        }
      } catch (error) {
        const validationErrors = parseValidationErrors(error)
        const message = getErrorMessage(error)
        if (validationErrors) actions.setErrors(validationErrors)
        else if (message) setErrorMessage(message)
      }
    },
  })

  useEffect(() => {
    if (!successMessage) return
    setTimeout(() => {
      setSuccessMessage('')
    }, feedbackDismissDuration)
  }, [successMessage])

  useEffect(() => {
    setErrorMessage('')
  }, [values])
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={20}>
        <div>
          <PasswordField
            name='current_password'
            label='Current Password'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.current_password}
            error={
              touched.current_password && errors.current_password
            }
          />
        </div>
        <div>
          <PasswordField
            name='password'
            label='New Password'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={touched.password && errors.password}
          />
        </div>
        <div>
          <PasswordField
            name='password_confirmation'
            label='Confirm Password'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password_confirmation}
            error={
              touched.password_confirmation &&
              errors.password_confirmation
            }
          />
        </div>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        {successMessage && (
          <SuccessText>{successMessage}</SuccessText>
        )}
        <Button
          variant='black'
          size='small'
          type='submit'
          disabled={!isValid || !dirty}
          isLoading={isSubmitting}
        >
          SAVE
        </Button>
      </Stack>
    </form>
  )
}

ChangePasswordForm.propTypes = {}

export default ChangePasswordForm
