import React from 'react'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text from '@dqp/common/components/Text/Text'
import CreditCard from '../CreditCard/CreditCard'
import useModal from '#utils/useModal'
import AddPaymentMethodModal from '#components/Modals/AddPaymentMethodModal'
import Loader from '#components/Loader/Loader'
import { useSuccessOrErrorModalContext } from '#context/SuccessOrErrorModalContext'
import useCards from '#utils/useCards'

const AddPaymentMethod = () => {
  const { cardsLoading, cards, cardsDispatch } = useCards()
  const { showSuccessModal } = useSuccessOrErrorModalContext()
  const { isOpen, closeModal, openModal } = useModal()

  const createPaymentMethod = () => {
    showSuccessModal('Payment method created successfully')
    closeModal()
    cardsDispatch({ type: 'FETCH_CARDS' })
  }

  if (cardsLoading) return <Loader isRelative height={150} />

  return (
    <>
      {cards.length > 0 ? (
        cards.map(card => (
          <div key={card.id} css={spacer.mrB20}>
            <CreditCard
              {...card}
              deleteCard={() =>
                cardsDispatch({
                  type: 'REMOVE',
                  payload: { id: card.id },
                })
              }
              setDefaultCard={() =>
                cardsDispatch({
                  type: 'SET_DEFAULT',
                  payload: { id: card.id },
                })
              }
            />
          </div>
        ))
      ) : (
        <Text color={colors.black} css={spacer.mrB20}>
          You have not added card details to your account.
        </Text>
      )}
      <Button variant='black' size='small' onClick={openModal}>
        ADD PAYMENT METHOD
      </Button>

      {isOpen && (
        <AddPaymentMethodModal
          isOpen={isOpen}
          closeModal={closeModal}
          onPrimaryButtonClick={createPaymentMethod}
        />
      )}
    </>
  )
}

AddPaymentMethod.propTypes = {}

export default AddPaymentMethod
