import React from 'react'
import PropTypes from 'prop-types'
import Title from '@dqp/common/components/Title/Title'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import { bool, object } from 'yup'
import { useFormik } from 'formik'
import ModalContainer from '#components/ModalContainer/ModalContainer'
import Stack from '#components/Stack/Stack'
import StripeCard from '#components/StripeCard/StripeCard'
import useCreateNewPaymentMethod from '#utils/useCreatePaymentMethod'

const validationSchema = object({
  isCardValid: bool()
    .oneOf([true], 'Please enter valid details')
    .required(),
})
function AddPaymentMethodModal({
  isOpen,
  closeModal,
  onPrimaryButtonClick,
}) {
  const { createNewPaymentMethod } = useCreateNewPaymentMethod()
  const {
    handleSubmit,
    setFieldError,
    errors,
    touched,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    validateOnBlur: false,
    initialValues: {
      isCardValid: false,
    },
    validationSchema,
    onSubmit: async formData => {
      try {
        await createNewPaymentMethod()
      } catch (error) {
        setFieldError('isCardValid', error.message)
        return
      }

      await onPrimaryButtonClick(formData.subscription)
    },
  })

  const handleCardChange = event => {
    if (event.error) {
      setFieldError('isCardValid', event.error.message)
    } else {
      setFieldValue('isCardValid', event.complete)
    }
  }
  return (
    <ModalContainer isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={handleSubmit}>
        <Stack className='d-flex flex-column text-left' spacing={30}>
          <Title color={colors.black} size='xLarge'>
            Add payment method
          </Title>

          <div>
            <StripeCard
              onBlur={() => setFieldTouched('isCardValid', true)}
              onChange={handleCardChange}
              error={touched.isCardValid && errors.isCardValid}
            />
          </div>
          <div className='d-flex align-items-center'>
            <Button
              size='small'
              variant='blue'
              css={spacer.mrR20}
              type='submit'
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              SAVE
            </Button>
            <Button
              type='button'
              onClick={closeModal}
              underlineColor={colors.black}
            >
              Cancel
            </Button>
          </div>
        </Stack>
      </form>
    </ModalContainer>
  )
}

AddPaymentMethodModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
}

AddPaymentMethodModal.defaultProps = {}
export default AddPaymentMethodModal
