import React, { useState, useEffect } from 'react'
import { object, string } from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import Button from '@dqp/common/components/Button/Button'
import TextField from '@dqp/common/components/TextField/TextField'
import { get } from 'lodash-es'
import {
  ErrorText,
  SuccessText,
} from '@dqp/common/components/Text/Text'
import { spacer } from '@dqp/common/styles/utilities'
import { useUpdateUser } from '#api/updateUser'
import parseValidationErrors from '#utils/parseValidationErrors'
import { feedbackDismissDuration } from '#globals/constants'
import getErrorMessage from '#utils/getErrorMessage'

const schema = object().shape({
  email: string()
    .email('Invalid email.')
    .required('Email is required.'),
  first_name: string().required('First Name is required.'),
  last_name: string().required('Last Name is required.'),
})

const ChangeEmailAndNameForm = ({ first_name, last_name, email }) => {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { updateUser } = useUpdateUser()
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    isValid,
    dirty,
    handleBlur,
    touched,
    isSubmitting,
  } = useFormik({
    validateOnBlur: true,
    validationSchema: schema,
    initialValues: {
      first_name,
      last_name,
      email,
    },

    onSubmit: async (formData, actions) => {
      try {
        const { data } = await updateUser({ variables: formData })
        const { id } = get(data, 'updateUser', {})
        if (id) {
          setSuccessMessage('Details updated')
        } else {
          setErrorMessage('There was an Error')
        }
      } catch (error) {
        const validationErrors = parseValidationErrors(error)
        const message = getErrorMessage(error)
        if (validationErrors) actions.setErrors(validationErrors)
        else if (message) setErrorMessage(message)
      }
    },
  })

  useEffect(() => {
    if (!successMessage) return
    setTimeout(() => {
      setSuccessMessage('')
    }, feedbackDismissDuration)
  }, [successMessage])

  useEffect(() => {
    setErrorMessage('')
  }, [values])
  return (
    <form onSubmit={handleSubmit}>
      <div className='container-fluid no-push' css={[spacer.padLR0]}>
        <div className='row'>
          <TextField
            className='col-sm-6'
            name='first_name'
            label='First Name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
            error={touched.first_name && errors.first_name}
            css={spacer.mrB20}
          />
          <TextField
            className='col-sm-6'
            name='last_name'
            label='Last Name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            error={touched.last_name && errors.last_name}
            css={spacer.mrB20}
          />
        </div>
      </div>
      <TextField
        name='email'
        label='Email Address'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={touched.email && errors.email}
        css={spacer.mrB20}
      />

      {errorMessage && (
        <ErrorText css={spacer.mrB20}>{errorMessage}</ErrorText>
      )}
      {successMessage && (
        <SuccessText css={spacer.mrB20}>{successMessage}</SuccessText>
      )}
      <Button
        variant='black'
        size='small'
        type='submit'
        disabled={!isValid || !dirty}
        isLoading={isSubmitting}
      >
        SAVE
      </Button>
    </form>
  )
}

ChangeEmailAndNameForm.propTypes = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
}

export default ChangeEmailAndNameForm
