import React from 'react'
import MyAccountForm from './MyAccountForm/MyAccountForm'
import Header from './Header'

function MyAccount() {
  return (
    <>
      <Header />
      <MyAccountForm />
    </>
  )
}

MyAccount.propTypes = {}
MyAccount.defaultProps = {}

export default MyAccount
