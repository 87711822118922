import React from 'react'
// import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'

const styles = {
  container: css`
    margin-top: 50px;
  `,
}
function Header() {
  return (
    <header className='container-md' css={styles.container}>
      <Text
        size='xLarge'
        color={colors.white}
        weight='bold'
        css={spacer.mrB20}
      >
        My Account
      </Text>
    </header>
  )
}

Header.propTypes = {}

export default Header
